import gql from 'graphql-tag'


const detailResponse = `
  id type name
  qty price discount discountPrice totalPrice
  doc {id type code name approvedAt closedAt}
  inventoryId inventory {id type code name}
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  parentId parent {
    id type name
    qty price discount discountPrice totalPrice
    inventoryId inventory {id type code name}
    unitId unit {id name}
    docId doc {id type code name}
  }
  children {
    id type name
    docId doc {id type code name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_CREATED = (templateType) => gql`subscription WATCH_ORDER_CREATED ($docType: String!, $docId: Int!) {
  orderCreated: watchDoc${templateType}OrderCreated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${detailResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {
    id docId
  }
}`

export const LIST_AVAILABLE_DOC = (templateType) => gql`query LIST_AVAILABLE_DOC ($docType: String!, $docId: Int!) {
  docs: listDoc${templateType}AvailableDoc (docType: $docType, docId: $docId) {
    id type code name
  }
}`
